export enum HotelDetailsTabId {
  description = 'description',
  tripAdvisorReviews = 'tripadvisor-reviews',
  holidayCheckReviews = 'holiday-check-reviews',
  tripadvisor = 'tripadvisor',
  holidaycheck = 'holidaycheck',
  reviews = 'reviews',
  roomOptions = 'room-options',
  googleReviews = 'google-reviews',
  google = 'google',
  map = 'map',
}

export const mapSelectedTab = (tabProvider: string): HotelDetailsTabId => {
  return (
    tabProvider === 'holidaycheck' ? HotelDetailsTabId.reviews : tabProvider
  ) as HotelDetailsTabId;
};
